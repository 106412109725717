import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import Logo from "@kikoff/components/src/v1/brand/Logo";
import useErrorConsumer from "@kikoff/hooks/src/useErrorConsumer";
import { combineClasses } from "@kikoff/utils/src/string";

import { GenericSpinner } from "@component/loading_indicators/loading_indicators";
import { logout } from "@feature/user";
import { newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`logout = Log out`),
  es: new FluentResource(`logout = Cerrar sesión`),
};

interface HeaderProps {
  showLogout?: boolean;
  className?: string;
  fixed?: boolean;
}

export default function Header({ showLogout, className, fixed }: HeaderProps) {
  const dispatch = useDispatch();
  const error = useErrorConsumer();

  const [scrolling, setScrolling] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  const l10n = newReactLocalization(RESOURCES);

  useEffect(() => {
    function onScroll() {
      setScrolling(window.scrollY > 20);
    }
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  const content = (
    <header
      className={combineClasses(
        styles["top-bar"],
        fixed && styles.fixed,
        className
      )}
      style={{
        boxShadow: scrolling && "0 0 10px 0 #ddd",
      }}
    >
      <LocalizationProvider l10n={l10n}>
        <div className={styles.spacer} />
        <Logo size="24px" />
        <div className={`${styles.spacer} ${styles.right}`}>
          {showLogout && (
            <span
              className={styles.logout}
              onClick={() => {
                setLoggingOut(true);
                dispatch(logout()).catch((e) => {
                  error.throw(e.message);
                });
              }}
            >
              {loggingOut && (
                <GenericSpinner
                  size="1em"
                  style={{ display: "inline-block", marginRight: "10px" }}
                />
              )}
              <Localized id="logout">Log out</Localized>
            </span>
          )}
        </div>
      </LocalizationProvider>
    </header>
  );

  return fixed ? (
    <>
      {content}
      <div style={{ height: 80, flexShrink: 0 }} />
    </>
  ) : (
    content
  );
}
