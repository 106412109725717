import { useEffect, useRef, useState } from "react";

type ExpandProps = JSX.IntrinsicElements["div"] & {
  children: React.ReactElement;
};

export default function Expand({ children, ...props }: ExpandProps) {
  const [width, setWidth] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setWidth((ref.current.firstChild as HTMLElement).scrollWidth);

    const t = setTimeout(() => {
      setWidth(null);
    }, 300);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <div
      ref={ref}
      {...props}
      style={{
        overflow: width == null ? null : "hidden",
        transition: "width 0.3s",
        width,
        ...props.style,
      }}
    >
      {children}
    </div>
  );
}
