import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./CheckAnimation.module.scss";

interface CheckAnimationProps {
  className?: string;
  color?: React.CSSProperties["color"];
  size?: React.CSSProperties["width"];
  show?: boolean;
}

export default function CheckAnimation({
  className,
  color = "currentcolor",
  size,
  show = true,
}: CheckAnimationProps) {
  return (
    <span className={combineClasses(styles["check-animation"], className)}>
      <svg viewBox="5 5 40 40" width={size} height={size}>
        {show && (
          <path
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
            style={{ stroke: color }}
          />
        )}
      </svg>
    </span>
  );
}
