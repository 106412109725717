import { useEffect, useState } from "react";

interface DelayProps {
  for: number;
  children: React.ReactNode;
}

export default function Delay({ for: delay, children }: DelayProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (show ? children : null) as React.ReactElement;
}
