import { useState } from "react";

export default function useObjectState<T>(initialState: T) {
  const [state, setState] = useState(initialState);

  const getNewState = (
    newState: Partial<T> | ((oldState: T) => Partial<T>),
    oldState
  ) => (typeof newState === "function" ? newState(oldState) : newState);

  function dispatch(newState: Partial<T> | ((oldState: T) => Partial<T>)) {
    setState((_state) => ({ ..._state, ...getNewState(newState, _state) }));
  }

  dispatch.replace = (newState: T | ((oldState: T) => T)) => {
    setState(newState);
  };

  dispatch.reset = () => {
    setState(initialState);
  };

  dispatch.delete = (key: keyof T) => {
    setState(({ [key]: discard, ..._state }) => _state as T);
  };

  return [state, dispatch] as const;
}
